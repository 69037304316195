import axios, { AxiosInstance } from 'axios';
import moment from 'moment';
import { utils } from './utils';
import { createAsyncThunk } from '@reduxjs/toolkit';


const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  // baseURL: `https://op8vg1apd5.execute-api.ap-southeast-1.amazonaws.com/prod`,
  withCredentials: false, // This is the default
  timeout: 60000
});

export const getPeriods = async () => {
  const currDate = moment().format("YYYY-MM-DD 00:30:00");
  const nextDate = moment().add(1, 'day').format("YYYY-MM-DD 00:30:00");

  return await apiClient.get(`/periods?filter=dateAnnounce||$gte||${currDate}&filter=dateAnnounce||$lte||${nextDate}&sort=lottoMaster.sorted,ASC`, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}
// async getPeriodById(id: number) {
//   return await apiClient.get('/periods/' + id, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//   });
// }
// async getProfile() {
//   return await apiClient.post('/member/profile', null, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//   });
// }
export const login = async (username: string, password: string) => {
  return await apiClient.post('/auth/login/admin', { username, password });
}
export const logout = () => {
  localStorage.clear();
}
// async changePassword(oldPassword: string, newPassword: string) {
//   return await apiClient.post('/auth/change-password', { oldPassword, newPassword }, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//   });
// }
export const getBills = async (periodId: number, memberId: string | null) => {
  let filter = 'filter=period.id||$eq||' + periodId;
  if (memberId) filter += '&filter=member.id||$eq||' + memberId

  return await apiClient.get('/bill?' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const getBillsToday = async (memberId: string | null) => {
  const { role, permissions } = utils.getRoleAndPermissions();

  const startDay = moment().format("YYYY-MM-DD 00:00:00");
  const endDay = moment().format("YYYY-MM-DD 23:59:59");
  let filter = 'filter=member.rank||$ne||1' + '&filter=createdAt||$gte||' + startDay + '&filter=createdAt||$lte||' + endDay;

  if (role === 'header') {
    if (permissions !== 'null') {
      const lottoMasterIds = utils.getLottoPermissions();
      filter += '&filter=period.lottoMaster.id||$in||' + lottoMasterIds.join(",");
    }
  }

  return await apiClient.get('/bill?' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const createBill = async (bill: any) => {
  return await apiClient.post('/bill', bill, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const getAllMembers = async (filter: string = '') => {
  if (filter !== '') {
    filter = '?' + filter;
  }

  return await apiClient.get('/member' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const createMember = async (member: any) => {
  return await apiClient.post('/member', member, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const updateMember = async (id: number, member: any) => {
  const response = await apiClient.patch('member/' + id, member, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
  return response;
}

export const deleteMember = async (id: number) => {
  const response = await apiClient.delete('member/' + id, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
  return response;
}

export const getResultByDay = async (filter: string) => {
  if (filter) {
    filter = '?' + filter;
  }
  return await apiClient.get('/periods' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

// async getTotalIncomeByPeriod(id: number) {
//   return await apiClient.get('/bill/total/' + id, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//   });
// }

export const createBets = async (bets: any) => {
  return await apiClient.post('/bet/bulk', bets, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}
// async getBetNumber(period: number, betDataMaster: number) {
//   return await apiClient.post('/bet/getBetNumber', { period, betDataMaster }, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//   });
// }
export const getOverViewCalculateByPeriod = async (period: number) => {
  return await apiClient.post('/periods/overview-calculate', { period: period }, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const updateAnnouncePeriod = async (period: number, body: any) => {
  return await apiClient.post('/periods/updateAnnounce/' + period, body, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}
export const resetPeriod = async (period: number) => {
  return await apiClient.post('/periods/resetPeriod', { period }, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}
// async getResultByDay(filter: string) {
//   if (filter) {
//     filter = '?' + filter;
//   }
//   return await apiClient.get('/periods' + filter, {
//     headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
//   });
// }
export const deleteBill = async (id: number) => {
  return await apiClient.delete('/bill/' + id, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}
export const cancelBill = async (id: number) => {
  return await apiClient.patch('/bill/' + id, { status: 'inactive' }, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}
export const getMemberResult = async (filter: string) => {
  return await apiClient.get('/periods/memberResult/' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const getOverviewResult = async (filter: string) => {
  return await apiClient.get('/periods/overviewResult/' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const getPeriodsOnly = async (filter: string) => {
  return await apiClient.get('/periods/periods-only/' + filter, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const getLottoMaster = async () => {
  return await apiClient.get('/lotto-master?sort=sorted,ASC', {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const updateLottoMaster = async (id: number, body: any) => {
  return await apiClient.put('/lotto-master/' + id, body, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const activeLottoPermission = async (id: number, status: string) => {
  return await apiClient.patch('/lotto-permission/' + id, { isActive: status }, {
    headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
  });
}

export const Profile = createAsyncThunk(
  "profile",
  async (_: void, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.post('/member/profile', null, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

export const ChangePassword = createAsyncThunk(
  "change-password",
  async (data: any, { rejectWithValue }: any) => {
    try {
      const response = await apiClient.post('/auth/change-password', data, {
        headers: { Authorization: 'Bearer ' + localStorage.getItem('token') }
      });
      return response;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
) 