import React, { FC } from 'react';
import moment from 'moment';
import { Typography } from 'antd';
import { PeriodType } from '../../types/period.type';
import Countdown, { CountdownRenderProps } from 'react-countdown';

const { Title, Text } = Typography;

type Props = {
    period: PeriodType
}


const LottoCard: FC<Props> = ({ period }) => {

    const renderer = (props: CountdownRenderProps) => {
        const { hours, minutes, seconds } = props.formatted;
        // Render a countdown
        return <span style={{ fontWeight: 'bold' }}>{hours}:{minutes}:{seconds}</span>;
    };

    const createdDate = moment(period.dateAnnounce).add(543, 'year').toDate();
    const periodDate = moment(createdDate).format('DD/MM/YYYY');

    const announceDate = moment(moment(period.dateAnnounce).toDate()).format('HH:mm น.');
    // const openDate = moment(moment(period.dateOpen).toDate()).format('HH:mm น.');
    const closeDate = moment(moment(period.dateClose).toDate()).format('HH:mm น.');

    const checkDate = () => {
        if (moment().toDate().getTime() > moment(period.dateClose).toDate().getTime()) return <Title level={5} type="danger" style={{ margin: 'auto', textAlign: 'center' }}>ปิดรับแล้ว</Title>;

        return <Title level={5} style={{ margin: 'auto', textAlign: 'center' }} type="danger"><Countdown renderer={renderer} date={moment(period.dateClose).toDate()} /></Title>
    }

    return (
        <div className={`lotto-card ${period.status === 'inactive' ? 'disabled-card' : ''}`}>
            <div className="header">
                <img src={process.env.REACT_APP_API_URL + period.lottoMaster?.pic} style={{ width: 100, height: 80, margin: 'auto' }} alt="line lotto" />
                <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>หวย{period.lottoMaster?.name}</Title>
            </div>
            <div className="content-card">
                {checkDate()}
                <Title level={5} style={{ margin: 'auto', textAlign: 'center' }}>งวด {periodDate}</Title>
            </div>
            <div className="time">
                <div className="time-content">
                    <Text strong style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>เวลาปิดรับ</Text>
                    <Text style={{ margin: 'auto', textAlign: 'center', color: 'red' }}>{closeDate}</Text>
                </div>
                <div className="time-content">
                    <Text strong style={{ margin: 'auto', textAlign: 'center', color: '#62bc71' }}>เวลออกผล</Text>
                    <Text style={{ margin: 'auto', textAlign: 'center', color: '#62bc71' }}>{announceDate}</Text>
                </div>
            </div>
        </div>
    )
}

export default LottoCard;